"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintToConsole = void 0;
const util_1 = require("util");
const log_1 = require("../types/log");
class PrintToConsole {
    constructor(console) {
        this.console = console;
    }
    init(printToConsole) {
        this.printToConsole = printToConsole;
    }
    error(...parameters) {
        if (this.printToConsole) {
            this.console.error(...parameters);
        }
    }
    info(...parameters) {
        if (this.printToConsole) {
            this.console.info(...parameters);
        }
    }
    log(...parameters) {
        if (this.printToConsole) {
            this.console.log(...parameters);
        }
    }
    trace(...parameters) {
        if (this.printToConsole) {
            this.console.trace(...parameters);
        }
    }
    warn(...parameters) {
        if (this.printToConsole) {
            this.console.warn(...parameters);
        }
    }
    printLog(log) {
        if (this.printToConsole) {
            const levelToMethod = ['log', 'warn', 'error', 'trace', 'info', 'error'];
            const method = levelToMethod[log.level || log_1.LogLevel.Debug];
            const tag = log.tag ? `[${log.tag}] ` : '';
            const location = [log.file || '', log.method || '', log.line || ''].filter(p => p !== '').join(':');
            const message = `${tag}${(0, util_1.format)(log.text)} ${location}`;
            this.console[method](message);
        }
    }
}
exports.PrintToConsole = PrintToConsole;
