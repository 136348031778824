"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryDeviceKeysTable = exports.IDBDeviceKeysTable = void 0;
class IDBDeviceKeysTable {
    constructor(devicekeys) {
        this.devicekeys = devicekeys;
    }
    add(item) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.devicekeys.add(item);
        });
    }
    count(sessionUUID) {
        return this.devicekeys.where({ sessionUUID }).count();
    }
    deleteBatch(batchSize) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.devicekeys.limit(batchSize).delete();
        });
    }
    getAll() {
        return this.devicekeys.toArray();
    }
}
exports.IDBDeviceKeysTable = IDBDeviceKeysTable;
class InMemoryDeviceKeysTable {
    constructor() {
        this.data = [];
        this.idCounter = 0;
    }
    getNextID() {
        return this.idCounter++;
    }
    add(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const id = this.getNextID();
            this.data.push(Object.assign(Object.assign({}, item), { id }));
        });
    }
    count(sessionUUID) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.data.filter(d => d.sessionUUID === sessionUUID).length;
        });
    }
    deleteBatch(batchSize) {
        return __awaiter(this, void 0, void 0, function* () {
            this.data.splice(0, batchSize);
        });
    }
    getAll() {
        return __awaiter(this, void 0, void 0, function* () {
            return [...this.data];
        });
    }
}
exports.InMemoryDeviceKeysTable = InMemoryDeviceKeysTable;
