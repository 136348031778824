"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverrideConsoleMethods = void 0;
const log_1 = require("../types/log");
class OverrideConsoleMethods {
    constructor(storage, window) {
        this.storage = storage;
        this.window = window;
    }
    init() {
        const self = this;
        this.window.console = function (console) {
            return Object.assign(Object.assign({}, console), { log: function (...parameters) {
                    console.log(...parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Debug, parameters);
                }, trace: function (...parameters) {
                    console.trace(...parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Trace, parameters);
                }, info: function (...parameters) {
                    console.info(...parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Info, parameters);
                }, warn: function (...parameters) {
                    console.warn(...parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Warning, parameters);
                }, error: function (...parameters) {
                    console.error(...parameters);
                    self.storage.addLogMessage(log_1.LogLevel.Error, parameters);
                } });
        }(this.window.console);
    }
}
exports.OverrideConsoleMethods = OverrideConsoleMethods;
