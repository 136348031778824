"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetStorage = void 0;
const storage_keys_1 = require("./storage-keys");
class ResetStorage {
    constructor(apiFeatures, session, database, localStorage, sessionStorage) {
        this.apiFeatures = apiFeatures;
        this.session = session;
        this.database = database;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
    }
    resetIfAppChanged(appID) {
        return __awaiter(this, void 0, void 0, function* () {
            const cachedAppID = this.localStorage.getItem(storage_keys_1.LocalStorageKey.AppID);
            if (cachedAppID !== null && cachedAppID !== appID) {
                const localStorageKeys = [storage_keys_1.LocalStorageKey.AppID, storage_keys_1.LocalStorageKey.DeviceData, storage_keys_1.LocalStorageKey.DeviceUDID];
                const sessionStorageKeys = [storage_keys_1.SessionStorageKey.SessionUDID, storage_keys_1.SessionStorageKey.SessionUUID];
                localStorageKeys.forEach(key => this.localStorage.removeItem(key));
                sessionStorageKeys.forEach(key => this.sessionStorage.removeItem(key));
                this.session.reset();
                if (yield this.apiFeatures.isIndexedDBSupported()) {
                    yield Promise.all([
                        this.database.sessions.clear(),
                        this.database.logs.clear(),
                        this.database.issues.clear(),
                        this.database.devicekeys.clear(),
                    ]);
                }
            }
            this.localStorage.setItem(storage_keys_1.LocalStorageKey.AppID, appID);
        });
    }
}
exports.ResetStorage = ResetStorage;
