"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterEventsHandlerBase = void 0;
class RegisterEventsHandlerBase {
    constructor(window) {
        this.window = window;
    }
    getURL() {
        if (this.window) {
            return this.window.location.href;
        }
        return '';
    }
    supportsPassiveEvents() {
        let passiveSupported = false;
        try {
            const options = {
                get passive() {
                    passiveSupported = true;
                    return false;
                }
            };
            this.window.addEventListener('test', null, options);
            this.window.removeEventListener('test', null, false);
        }
        catch (err) {
            passiveSupported = false;
        }
        return passiveSupported;
    }
}
exports.RegisterEventsHandlerBase = RegisterEventsHandlerBase;
