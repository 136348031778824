"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemorySessionsTable = exports.IDBSessionsTable = void 0;
class IDBSessionsTable {
    constructor(sessions) {
        this.sessions = sessions;
    }
    addIfMissing(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const count = yield this.sessions.where({ uuid: item.uuid }).count();
            if (count === 0) {
                yield this.sessions.add(item);
            }
        });
    }
    delete(uuid) {
        return this.sessions.delete(uuid);
    }
    getAll() {
        return this.sessions.toArray();
    }
    update(uuid, data) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.sessions.update(uuid, data);
        });
    }
}
exports.IDBSessionsTable = IDBSessionsTable;
class InMemorySessionsTable {
    constructor() {
        this.data = [];
    }
    addIfMissing(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const exists = this.data.some(entry => entry.uuid === item.uuid);
            if (!exists) {
                this.data.push(item);
            }
        });
    }
    delete(uuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const idx = this.data.findIndex(d => d.uuid === uuid);
            if (idx !== -1) {
                this.data.splice(idx, 1);
            }
        });
    }
    getAll() {
        return __awaiter(this, void 0, void 0, function* () {
            return [...this.data];
        });
    }
    update(uuid, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const idx = this.data.findIndex(d => d.uuid === uuid);
            if (idx !== -1) {
                this.data[idx] = Object.assign(Object.assign({}, this.data[idx]), data);
            }
        });
    }
}
exports.InMemorySessionsTable = InMemorySessionsTable;
