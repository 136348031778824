"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterErrorHandler = void 0;
class RegisterErrorHandler {
    constructor(window) {
        this.window = window;
    }
    init(bugfender) {
        this.window.addEventListener('error', (e) => {
            const { message: msg, lineno: line, filename, error } = e;
            let stack;
            if (error && error.stack) {
                stack = error.stack;
            }
            if (typeof msg === 'string') {
                bugfender.sendCrash(msg, [
                    `Error: ${msg}`,
                    `URL: ${filename ? filename : 'N/A'}`,
                    `Line: ${line ? line : 'N/A'}`,
                    `Stack: ${stack ? stack : 'N/A'}`,
                ].join('\n'));
            }
        });
    }
}
exports.RegisterErrorHandler = RegisterErrorHandler;
