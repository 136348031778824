"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bugfender = void 0;
const log_1 = require("./types/log");
const issue_1 = require("./types/issue");
const operation_modes_1 = require("./feature-detection/operation-modes");
const utils_1 = require("./utils");
class Bugfender {
    constructor(apiFeatures, operationModes, provider, window) {
        this.apiFeatures = apiFeatures;
        this.operationModes = operationModes;
        this.provider = provider;
        this.window = window;
        this.LogLevel = log_1.LogLevel;
        this.isInitialized = false;
        this.commandsQueue = [];
    }
    init(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const sdkOptions = this.provider.getSDKOptions();
            this.options = sdkOptions.init(options);
            if ((yield this.operationModes.getMode()) === operation_modes_1.OperationMode.D) {
                console.error(`Bugfender: mode of operation D is not supported`);
                return;
            }
            this.provider.setOptions(this.options);
            this.device = this.provider.getDevice();
            this.printToConsole = this.provider.getPrintToConsole();
            this.processor = yield this.provider.getStorageProcessor();
            this.resetStorage = yield this.provider.getResetStorage();
            this.session = yield this.provider.getCurrentSession();
            this.storage = yield this.provider.getStorageWriterHub();
            this.userFeedback = yield this.provider.getUserFeedback();
            this.channel = yield this.provider.getChannel();
            this.logger = this.provider.getLogger('Bugfender');
            this.logger.log('DEBUG', {
                debug: this.options.debug,
                operation: {
                    mode: yield this.operationModes.getMode(),
                    isMultiTab: yield this.operationModes.isMultiTab(),
                    isMultiThread: yield this.operationModes.isMultiThread(),
                },
                features: {
                    broadcastChannel: yield this.apiFeatures.isBroadcastChannelSupported(),
                    indexedDB: yield this.apiFeatures.isIndexedDBSupported(),
                    localStorage: this.apiFeatures.isLocalStorageSupported(),
                    sessionStorage: this.apiFeatures.isSessionStorageSupported(),
                    worker: this.apiFeatures.isWorkerSupported(),
                }
            });
            yield this.resetStorage.resetIfAppChanged(this.options.appID);
            this.storage.init(this.options);
            const browser = this.provider.getBrowser();
            this.storage.addDeviceKey('$browser', browser.getName());
            if (this.options.logBrowserEvents) {
                this.registerBrowserEventsHandler = yield this.provider.getRegisterBrowserEventsHandler();
                this.registerBrowserEventsHandler.init();
            }
            if (this.options.logUIEvents) {
                this.registerUIEventsHandler = yield this.provider.getRegisterUIEventsHandler();
                this.registerUIEventsHandler.init();
            }
            if (this.options.registerErrorHandler) {
                this.registerErrorHandler = yield this.provider.getRegisterErrorHandler();
                this.registerErrorHandler.init(this);
            }
            if (this.options.overrideConsoleMethods) {
                this.overrideConsoleMethods = yield this.provider.getOverrideConsoleMethods();
                this.overrideConsoleMethods.init();
            }
            this.printToConsole.init(this.options.printToConsole);
            window.addEventListener('beforeunload', () => {
                this.processor.run();
            });
            this.commandsQueue.forEach(cmd => cmd());
            this.commandsQueue = [];
            this.isInitialized = true;
        });
    }
    forceSendOnce() {
        this.channel.notifyForceSend();
    }
    getDeviceURL() {
        return __awaiter(this, void 0, void 0, function* () {
            return `${this.options.baseURL}/intent/${this.options.appID}/device/${this.device.getUDID()}`;
        });
    }
    getSessionURL() {
        return __awaiter(this, void 0, void 0, function* () {
            return `${this.options.baseURL}/intent/${this.options.appID}/session/${this.session.getUUID()}`;
        });
    }
    getUserFeedback(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.userFeedback.show(options);
        });
    }
    log(...parameters) {
        this.storage.addLogMessage(log_1.LogLevel.Debug, [...parameters]);
        this.printToConsole.log(...parameters);
    }
    warn(...parameters) {
        this.storage.addLogMessage(log_1.LogLevel.Warning, [...parameters]);
        this.printToConsole.warn(...parameters);
    }
    error(...parameters) {
        this.storage.addLogMessage(log_1.LogLevel.Error, [...parameters]);
        this.printToConsole.error(...parameters);
    }
    trace(...parameters) {
        this.storage.addLogMessage(log_1.LogLevel.Trace, [...parameters]);
        this.printToConsole.trace(...parameters);
    }
    info(...parameters) {
        this.storage.addLogMessage(log_1.LogLevel.Info, [...parameters]);
        this.printToConsole.info(...parameters);
    }
    fatal(...parameters) {
        this.storage.addLogMessage(log_1.LogLevel.Fatal, [...parameters]);
        this.printToConsole.error(...parameters);
    }
    removeDeviceKey(key) {
        this.storage.addDeviceKey(key, null);
        this.printToConsole.info(`Device key "${key}" removed`);
    }
    sendLog(log) {
        log.url = log.url || (this.window) ? this.window.location.href : '';
        this.storage.addLogEntry(log);
        this.printToConsole.printLog(log);
    }
    sendIssue(title, text) {
        return __awaiter(this, void 0, void 0, function* () {
            this.printToConsole.warn(`Issue: ${title}.\n${text}`);
            return this.storage.addIssue(issue_1.IssueType.Issue, title, text);
        });
    }
    sendCrash(title, text) {
        return __awaiter(this, void 0, void 0, function* () {
            this.printToConsole.error(`Crash: ${title}.\n${text}`);
            return this.storage.addIssue(issue_1.IssueType.Crash, title, text);
        });
    }
    sendUserFeedback(title, text) {
        return __awaiter(this, void 0, void 0, function* () {
            this.printToConsole.info(`User Feedback: ${title}.\n${text}`);
            return this.storage.addIssue(issue_1.IssueType.Feedback, title, text);
        });
    }
    setDeviceKey(key, value) {
        this.printToConsole.info(`Device key "${key}" set to "${value}"`);
        this.storage.addDeviceKey(key, value);
    }
    setForceEnabled(state) {
        this.provider.getStorageGuard()
            .then(guard => guard.setDeviceForceEnabled(state));
    }
}
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "forceSendOnce", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], Bugfender.prototype, "getDeviceURL", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], Bugfender.prototype, "getSessionURL", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], Bugfender.prototype, "getUserFeedback", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "log", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "warn", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "error", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "trace", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "info", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "fatal", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "removeDeviceKey", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "sendLog", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", Promise)
], Bugfender.prototype, "sendIssue", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", Promise)
], Bugfender.prototype, "sendCrash", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", Promise)
], Bugfender.prototype, "sendUserFeedback", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, Object]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "setDeviceKey", null);
__decorate([
    (0, utils_1.Enqueue)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], Bugfender.prototype, "setForceEnabled", null);
exports.Bugfender = Bugfender;
