"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterUIEventsHandler = void 0;
const register_events_handler_base_1 = require("./register-events-handler.base");
class RegisterUIEventsHandler extends register_events_handler_base_1.RegisterEventsHandlerBase {
    constructor(storage, window) {
        super(window);
        this.storage = storage;
        this.window = window;
    }
    init() {
        if (!this.window.document.addEventListener) {
            return;
        }
        const options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        document.addEventListener('click', (e) => {
            if (e.target instanceof Element) {
                this.storage.addLogEntry(this.getLog(e.target, 'click'));
            }
        }, options);
        document.addEventListener('focusin', (e) => {
            if (e.target instanceof Element) {
                this.storage.addLogEntry(this.getLog(e.target, 'focus'));
            }
        }, options);
        document.addEventListener('focusout', (e) => {
            if (e.target instanceof Element) {
                this.storage.addLogEntry(this.getLog(e.target, 'blur'));
            }
        }, options);
        this.window.addEventListener('submit', (e) => {
            this.storage.addLogEntry(this.getLog(e.target, 'submit'));
        }, options);
    }
    getLog(target, eventName) {
        return {
            tag: 'js_event',
            text: JSON.stringify(this.getEventData(target, eventName)),
            url: this.getURL(),
        };
    }
    getPathTo(element) {
        if (element.id !== '') {
            return 'id("' + element.id + '")';
        }
        if (element === document.body) {
            return element.tagName;
        }
        if (element.tagName === 'HTML') {
            return element.tagName;
        }
        let ix = 0;
        const siblings = element.parentNode.childNodes;
        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i];
            if (sibling === element) {
                return this.getPathTo(element.parentNode) + '/' + element.tagName + '[' + (ix + 1) + ']';
            }
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
                ix++;
            }
        }
    }
    getEventData(target, eventName) {
        if (target instanceof Element) {
            const text = (target.childElementCount <= 1) ? target.textContent : undefined;
            const form = (target.tagName === 'FORM') ? target : null;
            return {
                event: eventName,
                url: (this.window) ? this.window.location.href : '',
                xpath: this.getPathTo(target),
                tag: target.tagName,
                id: target.id,
                text: text,
                action: (form && form.action) ? form.action : '',
            };
        }
        return null;
    }
}
exports.RegisterUIEventsHandler = RegisterUIEventsHandler;
