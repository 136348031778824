"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Enqueue = exports.CacheDecoratorFactory = exports.createDeferred = void 0;
function createDeferred() {
    const deferred = {
        isFulfilled: false,
        promise: undefined,
        resolve: undefined,
        reject: undefined,
    };
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = (value) => {
            deferred.isFulfilled = true;
            resolve(value);
        };
        deferred.reject = (reason) => {
            deferred.isFulfilled = true;
            reject(reason);
        };
    });
    return deferred;
}
exports.createDeferred = createDeferred;
function CacheDecoratorFactory(cache) {
    return function CacheDecorator() {
        return function (_, propertyKey, descriptor) {
            const method = descriptor.value;
            descriptor.value = function () {
                if (!cache.has(propertyKey)) {
                    cache.set(propertyKey, method.apply(this));
                }
                return cache.get(propertyKey);
            };
        };
    };
}
exports.CacheDecoratorFactory = CacheDecoratorFactory;
function Enqueue() {
    return function (_target, _propertyKey, descriptor) {
        const method = descriptor.value;
        descriptor.value = function (...args) {
            const self = this;
            if (this.isInitialized) {
                descriptor.value = method;
                return method.apply(this, args);
            }
            else {
                return new Promise((resolve, reject) => {
                    this.commandsQueue.push(function BugfenderPreInitQueue() {
                        return __awaiter(this, void 0, void 0, function* () {
                            try {
                                resolve(method.apply(self, args));
                            }
                            catch (err) {
                                reject(err);
                            }
                        });
                    });
                });
            }
        };
    };
}
exports.Enqueue = Enqueue;
