"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriterHub = void 0;
const storage_writer_sanitizer_1 = require("./storage-writer.sanitizer");
const util_1 = require("util");
class StorageWriterHub {
    constructor(callInfoFactory, channel, device, handler, options, session, storageGuard, uuidFactory, window) {
        this.callInfoFactory = callInfoFactory;
        this.channel = channel;
        this.device = device;
        this.handler = handler;
        this.options = options;
        this.session = session;
        this.storageGuard = storageGuard;
        this.uuidFactory = uuidFactory;
        this.window = window;
        this.sanitizer = new storage_writer_sanitizer_1.StorageWriterSanitizer();
    }
    init(options) {
        const sessionUUID = this.session.getUUID();
        this.options = options;
        this.postMessage({
            type: 'init',
            data: { options, sessionUUID },
        }, false);
    }
    postMessage(message, checkStorageGuard = true) {
        if (checkStorageGuard && !this.storageGuard.canWrite()) {
            return;
        }
        try {
            this.handler.postMessage(this.sanitizer.sanitize(message));
        }
        catch (err) {
        }
    }
    addDeviceKey(key, value) {
        if (typeof key !== 'string') {
            throw new Error(`The device-key 'key' must be a string.`);
        }
        this.device.setDeviceKey(key, value);
        this.postMessage({
            type: 'device-key',
            data: { key, value, callInfo: this.callInfoFactory.create() },
        });
    }
    addLogEntry(log) {
        this.postMessage({
            type: 'log-entry',
            data: { log, callInfo: this.callInfoFactory.create() },
        });
    }
    addLogMessage(level, parameters) {
        const log = {};
        const [, ...textParams] = parameters;
        let [textFormat] = parameters;
        let text;
        try {
            textFormat = this.sanitizer.toString(textFormat);
        }
        catch (_a) {
            textFormat = (0, util_1.format)(textFormat);
        }
        if (textParams.length > 0) {
            text = (0, util_1.format)(textFormat, ...textParams);
        }
        else {
            text = textFormat;
        }
        log.level = level;
        log.text = text;
        log.url = (this.window) ? this.window.location.href : null;
        this.addLogEntry(log);
    }
    addIssue(type, title, text) {
        if (typeof title !== 'string') {
            throw new Error(`The crash/issue/feedback 'title' must be a string.`);
        }
        const uuid = this.uuidFactory.create();
        this.postMessage({
            type: 'issue',
            data: { type, title, uuid, text, callInfo: this.callInfoFactory.create() },
        });
        this.channel.notifyForceSend();
        return `${this.options.baseURL}/intent/${this.options.appID}/issue/${uuid}`;
    }
}
exports.StorageWriterHub = StorageWriterHub;
