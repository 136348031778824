"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogFactory = void 0;
const util_1 = require("util");
const log_1 = require("./types/log");
class LogFactory {
    constructor(getNow = () => new Date(), maxLogLength = 32000) {
        this.getNow = getNow;
        this.maxLogLength = maxLogLength;
        this.lastAbsoluteTime = 0;
    }
    createFromDeviceKey(key, value, callInfo) {
        return this.createFromEntry({
            tag: 'bf_key_value',
            text: `Set device data "${key}"="${value}"`,
        }, callInfo);
    }
    createFromEntry(log, callInfo) {
        const now = this.getNow();
        let absoluteTime = now.getTime();
        if (absoluteTime <= this.lastAbsoluteTime) {
            absoluteTime = this.lastAbsoluteTime + 1;
        }
        this.lastAbsoluteTime = absoluteTime;
        let text = (0, util_1.format)(log.text || '');
        if (text.length >= this.maxLogLength) {
            text = text.substring(0, this.maxLogLength) + '…';
        }
        return {
            x: now.toISOString(),
            t: text,
            m: log.method || callInfo.method || '',
            at: absoluteTime,
            tg: log.tag || '',
            f: log.file || callInfo.file || '',
            l: log.line || callInfo.line || 0,
            ll: log.level || log_1.LogLevel.Debug,
            u: log.url || '',
        };
    }
    createFromIssue(issueUUID, callInfo) {
        return this.createFromEntry({
            level: log_1.LogLevel.Error,
            tag: 'bf_issue',
            text: issueUUID,
        }, callInfo);
    }
    createGapLog(logs) {
        const text = JSON.stringify({
            bf_start_date: logs[0].at,
            bf_end_date: logs[logs.length - 1].at,
        });
        return {
            x: logs[0].x,
            t: text,
            m: '',
            at: logs[0].at,
            tg: 'bf_gap_log',
            f: '',
            l: 0,
            ll: 0,
            u: logs[0].u,
        };
    }
}
exports.LogFactory = LogFactory;
