"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriterSanitizer = void 0;
const log_1 = require("./types/log");
class StorageWriterSanitizer {
    toString(value) {
        const isToString = ['string', 'symbol', 'bigint', 'function'].includes(typeof value) || value instanceof Error;
        if (isToString) {
            try {
                return String(value);
            }
            catch (_a) {
                return JSON.stringify(value);
            }
        }
        else {
            try {
                return JSON.stringify(value);
            }
            catch (_b) {
                return String(value);
            }
        }
    }
    toNumber(value) {
        value = Number(value);
        if (!isNaN(value)) {
            return value;
        }
        else {
            throw new Error(`Can't be casted to number`);
        }
    }
    sanitize(message) {
        switch (message.type) {
            case 'device-key': {
                const isValidValue = ['boolean', 'number', 'string'].includes(typeof message.data.value);
                message.data.key = this.toString(message.data.key);
                if (!isValidValue) {
                    message.data.value = this.toString(message.data.value);
                }
                break;
            }
            case 'issue': {
                message.data.title = this.toString(message.data.title);
                message.data.text = this.toString(message.data.text);
                break;
            }
            case 'log-entry': {
                const strings = ['tag', 'method', 'file', 'text', 'url'];
                const hasLine = 'line' in message.data.log && typeof message.data.log.line !== 'undefined';
                const isValidLine = hasLine && typeof message.data.log.line === 'number';
                const hasLevel = 'level' in message.data.log && typeof message.data.log.level !== 'undefined';
                const isValidLevel = hasLevel && log_1.LOG_LEVELS.includes(message.data.log.level);
                strings.forEach(key => {
                    const hasValue = key in message.data.log && typeof message.data.log[key] !== 'undefined';
                    if (hasValue) {
                        try {
                            message.data.log[key] = this.toString(message.data.log[key]);
                        }
                        catch (_a) {
                            delete message.data.log[key];
                        }
                    }
                });
                if (hasLine && !isValidLine) {
                    try {
                        message.data.log.line = this.toNumber(message.data.log.line);
                    }
                    catch (_a) {
                        delete message.data.log.line;
                    }
                }
                if (hasLevel && !isValidLevel) {
                    try {
                        message.data.log.level = this.toNumber(message.data.log.level);
                        if (!log_1.LOG_LEVELS.includes(message.data.log.level)) {
                            delete message.data.log.level;
                        }
                    }
                    catch (_b) {
                        delete message.data.log.level;
                    }
                }
                break;
            }
        }
        return message;
    }
}
exports.StorageWriterSanitizer = StorageWriterSanitizer;
